import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlockContent from "@sanity/block-content-to-react"
import urlBuilder from "@sanity/image-url"
import { css } from "@emotion/core"

import Carousel from "../components/Carousel"
import FeaturedWeddings from "../components/FeaturedWeddings/index"
import ContactForm from "../components/ContactForm"

import "./index.css"

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
      }
    }

    sanityHomepage(id: { eq: "-e07ca5b6-bcc6-5d90-8489-39702cd11394" }) {
      sliderImages {
        imageUpload {
          asset {
            id
          }
        }
        imageDescription
      }
      _rawIntroText(resolveReferences: { maxDepth: 5 })

      documentaryStyle {
        title
        description
        image {
          asset {
            id
          }
          hotspot {
            x
            y
          }
        }
      }

      familyWeddings {
        title
        description
        image {
          asset {
            id
          }
          hotspot {
            x
            y
          }
        }
      }

      customerQuote {
        quote
        name
      }

      featuredWeddings {
        title
        slug {
          current
        }
        weddingImageHero {
          imageUpload {
            asset {
              id
            }
          }
        }
        weddingImages {
          imageUpload {
            asset {
              id
            }
          }
        }
      }
    }

    allSanityWedding {
      edges {
        node {
          title
          slug {
            current
          }
          date
          weddingImages {
            imageUpload {
              asset {
                id
              }
            }
          }
        }
      }
    }
  }
`

const IndexPage = ({ data, location }) => {
  const urlFor = (source) =>
    urlBuilder({ projectId: "yk6mnr40", dataset: "production" }).image(source)

  const homepage = data.sanityHomepage
  const sliderImages = data.sanityHomepage.sliderImages
  const documentaryStyleImages = data.sanityHomepage.documentaryStyle
  const familyWeddings = data.sanityHomepage.familyWeddings
  const customerQuote = data.sanityHomepage.customerQuote
  const featuredWeddings = data.sanityHomepage.featuredWeddings

  const blockRenderer = ({ node, children }) => {
    switch (node.style) {
      case "h1":
        return (
          <div css={sectionText}>
            <h1>{children}</h1>
          </div>
        )
      case "h2":
        return (
          <div css={sectionText}>
            <h2>{children}</h2>
          </div>
        )
      case "h3":
        return (
          <div css={sectionText}>
            <h3>{children}</h3>
          </div>
        )
      case "h4":
        return (
          <div css={sectionText}>
            <h4>{children}</h4>
          </div>
        )
      case "h5":
        return (
          <div css={sectionText}>
            <h5>{children}</h5>
          </div>
        )
      case "h6":
        return (
          <div css={sectionText}>
            <h6>{children}</h6>
          </div>
        )
      case "blockquote":
        return (
          <div css={sectionText}>
            <blockquote>{children}</blockquote>
          </div>
        )
      default:
        return (
          <div css={sectionText}>
            <p>{children}</p>
          </div>
        )
    }
  }

  // const sectionContent = css`
  //   margin-left: var(--size-1);
  //   margin-right: var(--size-1);
  //   @media (min-width: 900px) {
  //     margin-left: 0;
  //     margin-right: 0;
  //   }
  // `

  const homepageStyles = css`
    > * + * {
      margin-top: var(--size-10);
    }
  `

  const introTextSectionStyles = css`
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: var(--size-10) 0; */
    /* background: var(--colour-off-white); */
  `

  const introTextStyles = css`
    max-width: 1000px;
    text-align: center;
  `

  const sectionText = css`
    max-width: 1000px;
  `

  const imageContainerStyles = css`
    max-width: 1000px;
  `

  const imageStyles = css`
    object-fit: cover;
    width: 100%;
    margin-bottom: var(--size-6);
  `
  const customerQuoteContainerStyling = css`
    position: relative;
    max-width: 800px;
    margin-top: calc(48px + 80px);
    margin-bottom: calc(48px + 80px);
    &:before {
      content: "";
      position: absolute;
      left: 24px;
      top: calc(var(--size-9) * -1);
      width: 100%;
      max-width: 200px;
      border-bottom: 1px solid #e2d6c7;
      @media (min-width: 900px) {
        left: 0;
        top: calc(var(--size-8) * -1);
      }
    }
    &:after {
      content: "";
      position: absolute;
      right: 24px;
      bottom: calc(var(--size-7) * -1);
      width: 100%;
      max-width: 200px;
      border-bottom: 1px solid #e2d6c7;
    }
  `
  const customerQuoteStyling = css`
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    font-style: italic;
    font-size: var(--size-2);
    &:before {
      content: url("data:image/svg+xml, %3Csvg%20width%3D%2243%22%20height%3D%2232%22%20viewBox%3D%220%200%2043%2032%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M32.7664%2031.9414C30.2075%2031.9414%2028.1683%2031.0641%2026.649%2029.3096C25.2096%2027.4752%2024.4899%2025.0427%2024.4899%2022.0121C24.4899%2017.7054%2025.6894%2013.7974%2028.0884%2010.2883C30.4874%206.69933%2034.2458%203.26991%2039.3636%200L42.0025%202.99078C38.564%205.78216%2035.8851%208.41404%2033.9659%2010.8864C32.0467%2013.3588%2031.0071%2015.552%2030.8472%2017.4661L31.327%2017.825C31.4869%2017.506%2031.8468%2017.2667%2032.4065%2017.1072C33.0463%2016.868%2033.686%2016.7483%2034.3257%2016.7483C36.165%2016.7483%2037.6443%2017.4661%2038.7639%2018.9017C39.9634%2020.2575%2040.5631%2022.0121%2040.5631%2024.1654C40.5631%2026.4783%2039.8434%2028.3525%2038.404%2029.7881C36.9646%2031.2237%2035.0854%2031.9414%2032.7664%2031.9414ZM8.77651%2031.9414C6.21759%2031.9414%204.17845%2031.0641%202.65909%2029.3096C1.2197%2027.4752%200.5%2025.0427%200.5%2022.0121C0.5%2017.7054%201.69949%2013.7974%204.09848%2010.2883C6.49747%206.69933%2010.2559%203.26991%2015.3737%200L18.0126%202.99078C14.5741%205.78216%2011.8952%208.41404%209.976%2010.8864C8.05681%2013.3588%207.01725%2015.552%206.85732%2017.4661L7.33712%2017.825C7.49705%2017.506%207.8569%2017.2667%208.41666%2017.1072C9.05639%2016.868%209.69612%2016.7483%2010.3359%2016.7483C12.1751%2016.7483%2013.6545%2017.4661%2014.774%2018.9017C15.9735%2020.2575%2016.5732%2022.0121%2016.5732%2024.1654C16.5732%2026.4783%2015.8535%2028.3525%2014.4141%2029.7881C12.9747%2031.2237%2011.0955%2031.9414%208.77651%2031.9414Z%22%20fill%3D%22%23E2D6C7%22%2F%3E%0A%3C%2Fsvg%3E%0A");
      display: inline-flex;
      position: absolute;
      left: 24px;
      top: -48px;
      @media (min-width: 900px) {
        content: url("data:image/svg+xml, %3Csvg%20width%3D%2243%22%20height%3D%2232%22%20viewBox%3D%220%200%2043%2032%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M32.7664%2031.9414C30.2075%2031.9414%2028.1683%2031.0641%2026.649%2029.3096C25.2096%2027.4752%2024.4899%2025.0427%2024.4899%2022.0121C24.4899%2017.7054%2025.6894%2013.7974%2028.0884%2010.2883C30.4874%206.69933%2034.2458%203.26991%2039.3636%200L42.0025%202.99078C38.564%205.78216%2035.8851%208.41404%2033.9659%2010.8864C32.0467%2013.3588%2031.0071%2015.552%2030.8472%2017.4661L31.327%2017.825C31.4869%2017.506%2031.8468%2017.2667%2032.4065%2017.1072C33.0463%2016.868%2033.686%2016.7483%2034.3257%2016.7483C36.165%2016.7483%2037.6443%2017.4661%2038.7639%2018.9017C39.9634%2020.2575%2040.5631%2022.0121%2040.5631%2024.1654C40.5631%2026.4783%2039.8434%2028.3525%2038.404%2029.7881C36.9646%2031.2237%2035.0854%2031.9414%2032.7664%2031.9414ZM8.77651%2031.9414C6.21759%2031.9414%204.17845%2031.0641%202.65909%2029.3096C1.2197%2027.4752%200.5%2025.0427%200.5%2022.0121C0.5%2017.7054%201.69949%2013.7974%204.09848%2010.2883C6.49747%206.69933%2010.2559%203.26991%2015.3737%200L18.0126%202.99078C14.5741%205.78216%2011.8952%208.41404%209.976%2010.8864C8.05681%2013.3588%207.01725%2015.552%206.85732%2017.4661L7.33712%2017.825C7.49705%2017.506%207.8569%2017.2667%208.41666%2017.1072C9.05639%2016.868%209.69612%2016.7483%2010.3359%2016.7483C12.1751%2016.7483%2013.6545%2017.4661%2014.774%2018.9017C15.9735%2020.2575%2016.5732%2022.0121%2016.5732%2024.1654C16.5732%2026.4783%2015.8535%2028.3525%2014.4141%2029.7881C12.9747%2031.2237%2011.0955%2031.9414%208.77651%2031.9414Z%22%20fill%3D%22%23E2D6C7%22%2F%3E%0A%3C%2Fsvg%3E%0A");
        left: -48px;
        top: -24px;
      }
    }
    &:after {
      content: url("data:image/svg+xml,%3Csvg%20width%3D%2243%22%20height%3D%2232%22%20viewBox%3D%220%200%2043%2032%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M10.2337%200.0585938C12.7926%200.0585938%2014.7917%200.975764%2016.2311%202.8101C17.7505%204.56469%2018.5102%206.95731%2018.5102%209.98796C18.5102%2014.2947%2017.3107%2018.2425%2014.9117%2021.8314C12.5127%2025.3406%208.75429%2028.7301%203.63645%2032L0.997559%2029.0093C4.43611%2026.2179%207.11498%2023.586%209.03417%2021.1136C10.9534%2018.6413%2011.9929%2016.448%2012.1529%2014.5339L11.6731%2014.175C11.5131%2014.4941%2011.1133%2014.7732%2010.4736%2015.0125C9.9138%2015.172%209.31405%2015.2517%208.67432%2015.2517C6.8351%2015.2517%205.31574%2014.5738%204.11624%2013.218C2.99672%2011.7824%202.43695%209.98796%202.43695%207.8346C2.43695%205.52174%203.15665%203.64752%204.59604%202.21195C6.03543%200.77638%207.91464%200.0585938%2010.2337%200.0585938ZM34.2235%200.0585938C36.7825%200.0585938%2038.7816%200.975764%2040.221%202.8101C41.7404%204.56469%2042.5001%206.95731%2042.5001%209.98796C42.5001%2014.2947%2041.3006%2018.2425%2038.9016%2021.8314C36.5026%2025.3406%2032.7442%2028.7301%2027.6263%2032L24.9874%2029.0093C28.426%2026.2179%2031.1049%2023.586%2033.0241%2021.1136C34.9432%2018.6413%2035.9828%2016.448%2036.1427%2014.5339L35.6629%2014.175C35.503%2014.4941%2035.1032%2014.7732%2034.4635%2015.0125C33.9037%2015.172%2033.3039%2015.2517%2032.6642%2015.2517C30.825%2015.2517%2029.3056%2014.5738%2028.1061%2013.218C26.9866%2011.7824%2026.4268%209.98796%2026.4268%207.8346C26.4268%205.52174%2027.1465%203.64752%2028.5859%202.21195C30.0253%200.77638%2031.9045%200.0585938%2034.2235%200.0585938Z%22%20fill%3D%22%23E2D6C7%22%2F%3E%0A%3C%2Fsvg%3E%0A");
      display: inline-flex;
      position: absolute;
      justify-content: flex-end;
      right: 24px;
      bottom: -24px;
      @media (min-width: 900px) {
        content: url("data:image/svg+xml,%3Csvg%20width%3D%2243%22%20height%3D%2232%22%20viewBox%3D%220%200%2043%2032%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M10.2337%200.0585938C12.7926%200.0585938%2014.7917%200.975764%2016.2311%202.8101C17.7505%204.56469%2018.5102%206.95731%2018.5102%209.98796C18.5102%2014.2947%2017.3107%2018.2425%2014.9117%2021.8314C12.5127%2025.3406%208.75429%2028.7301%203.63645%2032L0.997559%2029.0093C4.43611%2026.2179%207.11498%2023.586%209.03417%2021.1136C10.9534%2018.6413%2011.9929%2016.448%2012.1529%2014.5339L11.6731%2014.175C11.5131%2014.4941%2011.1133%2014.7732%2010.4736%2015.0125C9.9138%2015.172%209.31405%2015.2517%208.67432%2015.2517C6.8351%2015.2517%205.31574%2014.5738%204.11624%2013.218C2.99672%2011.7824%202.43695%209.98796%202.43695%207.8346C2.43695%205.52174%203.15665%203.64752%204.59604%202.21195C6.03543%200.77638%207.91464%200.0585938%2010.2337%200.0585938ZM34.2235%200.0585938C36.7825%200.0585938%2038.7816%200.975764%2040.221%202.8101C41.7404%204.56469%2042.5001%206.95731%2042.5001%209.98796C42.5001%2014.2947%2041.3006%2018.2425%2038.9016%2021.8314C36.5026%2025.3406%2032.7442%2028.7301%2027.6263%2032L24.9874%2029.0093C28.426%2026.2179%2031.1049%2023.586%2033.0241%2021.1136C34.9432%2018.6413%2035.9828%2016.448%2036.1427%2014.5339L35.6629%2014.175C35.503%2014.4941%2035.1032%2014.7732%2034.4635%2015.0125C33.9037%2015.172%2033.3039%2015.2517%2032.6642%2015.2517C30.825%2015.2517%2029.3056%2014.5738%2028.1061%2013.218C26.9866%2011.7824%2026.4268%209.98796%2026.4268%207.8346C26.4268%205.52174%2027.1465%203.64752%2028.5859%202.21195C30.0253%200.77638%2031.9045%200.0585938%2034.2235%200.0585938Z%22%20fill%3D%22%23E2D6C7%22%2F%3E%0A%3C%2Fsvg%3E%0A");
        right: -48px;
        bottom: -24px;
      }
    }
  `

  const customerQuoteName = css`
    margin-top: var(--size-2);
  `

  return (
    <Layout>
      <SEO description={data.site.siteMetadata.description} />
      <div css={homepageStyles}>
        <div>
          {/* <Carousel sliderImages={sliderImages} urlFor={urlFor} /> */}

          <Carousel sliderImages={sliderImages} />
        </div>

        <div className="sectionContent" css={introTextSectionStyles}>
          <div css={introTextStyles}>
            <BlockContent
              blocks={homepage._rawIntroText}
              serializers={{
                types: { block: blockRenderer },
              }}
              // className="projectContent"
            />
          </div>
        </div>

        <div className="sectionContent" css={imageContainerStyles}>
          <h2>{documentaryStyleImages.title}</h2>
          <img
            css={imageStyles}
            sizes="(min-width: 800px) 1000px, 100vw"
            srcSet={[
              urlFor(documentaryStyleImages.image.asset.id)
                .auto("format")
                .width(2000)
                .height(894)
                .fit("crop")
                .focalPoint(
                  documentaryStyleImages.image.hotspot == null
                    ? 0.5
                    : documentaryStyleImages.image.hotspot.x,
                  documentaryStyleImages.image.hotspot == null
                    ? 0.5
                    : documentaryStyleImages.image.hotspot.y
                )
                .url() + ` 2000w`,
              urlFor(documentaryStyleImages.image.asset.id)
                .auto("format")
                .width(800)
                .height(357)
                .fit("crop")
                .focalPoint(
                  documentaryStyleImages.image.hotspot == null
                    ? 0.5
                    : documentaryStyleImages.image.hotspot.x,
                  documentaryStyleImages.image.hotspot == null
                    ? 0.5
                    : documentaryStyleImages.image.hotspot.y
                )
                .url() + ` 800w`,
            ]}
            src={urlFor(documentaryStyleImages.image.asset.id)
              .auto("format")
              .width(800)
              .height(357)
              .fit("crop")
              .focalPoint(
                documentaryStyleImages.image.hotspot == null
                  ? 0.5
                  : documentaryStyleImages.image.hotspot.x,
                documentaryStyleImages.image.hotspot == null
                  ? 0.5
                  : documentaryStyleImages.image.hotspot.y
              )
              .url()}
            alt=""
          />
          <p>{documentaryStyleImages.description}</p>
        </div>
        <div className="sectionContent" css={customerQuoteContainerStyling}>
          <div css={customerQuoteStyling}>
            <div>{customerQuote.quote}</div>
            <div css={customerQuoteName}>{customerQuote.name}</div>
          </div>
        </div>
        <div className="sectionContent" css={imageContainerStyles}>
          <h2>{familyWeddings.title}</h2>
          <img
            css={imageStyles}
            sizes="(min-width: 800px) 1000px, 100vw"
            srcSet={[
              urlFor(familyWeddings.image.asset.id)
                .auto("format")
                .width(2000)
                .height(1000)
                .fit("crop")
                .focalPoint(
                  familyWeddings.image.hotspot == null
                    ? 0.5
                    : familyWeddings.image.hotspot.x,
                  familyWeddings.image.hotspot == null
                    ? 0.5
                    : familyWeddings.image.hotspot.y
                )
                .url() + ` 2000w`,

              urlFor(familyWeddings.image.asset.id)
                .auto("format")
                .width(800)
                .height(400)
                .fit("crop")
                .focalPoint(
                  familyWeddings.image.hotspot == null
                    ? 0.5
                    : familyWeddings.image.hotspot.x,
                  familyWeddings.image.hotspot == null
                    ? 0.5
                    : familyWeddings.image.hotspot.y
                )
                .url() + ` 800w`,
            ]}
            src={urlFor(familyWeddings.image.asset.id)
              .auto("format")
              .width(800)
              .height(400)
              .fit("crop")
              .focalPoint(
                familyWeddings.image.hotspot == null
                  ? 0.5
                  : familyWeddings.image.hotspot.x,
                familyWeddings.image.hotspot == null
                  ? 0.5
                  : familyWeddings.image.hotspot.y
              )
              .url()}
            alt=""
          />
          <p>{familyWeddings.description}</p>
        </div>
        <div
          className="sectionContent"
          css={css`
            max-width: 1000px;
          `}
        >
          <FeaturedWeddings
            title={featuredWeddings.title}
            weddings={featuredWeddings}
          />
        </div>

        <div
          className="sectionContent"
          css={css`
            max-width: 800px;
          `}
        >
          <ContactForm location={location.pathname} />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
